<template>
  <div>
    <div style="background:#3e06a5">
      <el-image width="1920" height="568" :src="bannerUrl"></el-image>
    </div>
    <div style="width:100%; height:68px; background:#475AFF">
      <div style="width:1320px; margin:0 auto; text-align:left">
        <ul>
          <router-link to="/components/web/joinus/case01">
            <li class="tabStyleLi">
              <div class="tabStyle"></div>
              <div
                class="tabStyle2"
                style="cursor: pointer;position: absolute; top:0; left:20px; padding-top:12px;  color: #ffffff;"
              >
                <div>01</div>
                <div>{{ $t('Nav.zhaopin') }}</div>
              </div>
            </li>
          </router-link>
          <router-link to="/components/web/joinus/case02">
            <li class="tabStyleLi">
              <div class="tabStyle"></div>
              <div
                style="cursor: pointer;position: absolute; top:0; left:20px; padding-top:12px;  color: #ffffff;"
              >
                <div>02</div>
                <div>{{ $t('Nav.peixunyufazhan') }}</div>
              </div>
            </li>
          </router-link>
          <router-link to="/components/web/joinus/case03">
            <li class="tabStyleLi">
              <div class="tabStyle"></div>
              <div
                style="cursor: pointer;position: absolute; top:0; left:20px; padding-top:12px;  color: #ffffff;"
              >
                <div>03</div>
                <div>{{ $t('Nav.shenghuoyufuli') }}</div>
              </div>
            </li>
          </router-link>
        </ul>
      </div>
    </div>
    <div
      style="width:1200px; margin:0 auto; height: 44px;box-shadow: 0px 1px 0px 0px rgba(71, 90, 255, 0.1);"
    >
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/' }"
          ><span style="color:#303133">{{
            $t('Nav.home')
          }}</span></el-breadcrumb-item
        >
        <el-breadcrumb-item
          ><span style="color:#303133">{{
            $t('Nav.abus')
          }}</span></el-breadcrumb-item
        >
      </el-breadcrumb>
    </div>
    <div style="height:30px"></div>
    <div style="width:1200px; margin:0 auto;height:500px">
      <div>
        <div style="float:left;width:500px; margin-top:150px;">
          <div
            style="width: 500px;text-align:center; height: 24px;font-size: 24px;font-family: Noto Sans S Chinese;font-weight: bold;color: #475AFF;line-height: 37px;"
          >
            {{ $t('Nav.peixunyufazhan') }}
          </div>
          <div style="text-indent:30px; margin-top:30px;text-align:left; ">
            {{ $t('Nav.qxfz07') }}
          </div>
          <div style="margin-top:30px">
            <router-link to="/components/web/joinus/case02">
              <el-button round> {{ $t('Nav.jinyibuliaojie') }}</el-button>
            </router-link>
          </div>
        </div>
        <div style="float:right;margin-top:50px;">
          <el-image
            :src="qygsImg1"
            style="width:640px; height:400px;"
          ></el-image>
        </div>
      </div>
    </div>

    <div style="clear: both;"></div>
    <div style="width:1200px; margin:0 auto;height:500px">
      <div>
        <div style="float:right;width:500px; margin-top:150px;">
          <div
            style="width: 500px;text-align:center; height: 24px;font-size: 24px;font-family: Noto Sans S Chinese;font-weight: bold;color: #475AFF;line-height: 37px;"
          >
            {{ $t('Nav.shenghuoyufuli') }}
          </div>
          <div style="text-indent:30px; margin-top:30px;text-align:left; ">
            {{ $t('Nav.qxfz08') }}
          </div>
          <div style="margin-top:30px">
            <router-link to="/components/web/joinus/case03">
              <el-button round> {{ $t('Nav.jinyibuliaojie') }}</el-button>
            </router-link>
          </div>
        </div>
        <div style="float:left;margin-top:50px;">
          <el-image
            :src="qygsImg2"
            style="width:640px; height:400px;"
          ></el-image>
        </div>
      </div>
    </div>
    <div style="clear: both; height:30px;"></div>
  </div>
</template>

<script>
export default {
  name: 'joinus',
  components: {},
  data() {
    return {
      bannerUrl: require('../../../assets/55.jpg'),
      qygsImg1: require('../../../assets/joinus/06-img1.png'),
      qygsImg2: require('../../../assets/joinus/06-img2.png'),
    }
  },
  mounted() {
    this.$bus.$emit('getCurNav', 'joinus')
  },
  methods: {},
}
</script>

<style>
ul {
  margin: 0;
}
.tabStyle {
  color: #ffffff;
  display: inline-block;
  height: 68px;
  width: 200px;
}
.tabStyleLi {
  display: inline-block;
  height: 68px;
  position: relative;
}
.tabStyle:hover {
  background: #3246f4 !important;
  opacity: 0.7;
  height: 68px;
  cursor: pointer;
}

.el-image {
  overflow: visible !important;
}
.el-breadcrumb {
  line-height: 44px;
}
.el-carousel__item h3 {
  color: #475669;
  font-size: 18px;
  opacity: 0.75;
  line-height: 300px;
  margin: 0;
}
.jjfaTitle {
  height: 20px;
  font-size: 21px;
  font-family: Noto Sans S Chinese;
  font-weight: 300;
  color: #2c3dd6;
  line-height: 37px;
  margin: 5px 0;
}
.jjfamiaoshu {
  height: 16px;
  font-size: 16px;
  font-family: Noto Sans S Chinese;
  font-weight: 300;
  color: #666666;
  line-height: 37px;
}
.grid-content {
  text-align: center;
  height: 250px;
  line-height: 50px;
  padding-top: 50px;
}
.grid-content:hover {
  cursor: pointer;
  background: #f6f8f9;
  height: 250px;
}
.grid-content2 {
  position: relative;
  cursor: pointer;
}
.imgText {
  font-size: 19px;
  font-family: Noto Sans S Chinese;
  font-weight: 400;
  color: #ffffff;
  margin: 20px;
}
.hzgs {
  height: 23px;
  font-size: 24px;
  font-family: Noto Sans S Chinese;
  font-weight: bold;
  color: #475aff;
  line-height: 37px;
}
</style>
